/* ResultsPage.css */


.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    
    margin-top: 20px;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    
    color: white;
    width: 100%;
    position: fixed; /* Ensure the header stays at the top */
    top: 0;
    left: 0;
    height: 60px; /* Adjust header height */
    z-index: 10; /* Make sure header stays above other content */
    box-sizing: border-box; /* Make sure padding and border are included in the height */
}
.sub-header-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.sub-header {
    font-size: 1.2rem;
}

.chart-container {
    width: 100%;
    max-width: 1200px;
    background-color: #ffffff; /* White background for chart container */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow to make charts stand out */
    margin-bottom: 40px;
}

.section {
    margin-bottom: 40px;
}

.section-header {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.no-data-text {
    font-size: 1rem;
    color: grey;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
}

.button {
    padding: 10px 20px;
    font-size: 1rem;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #e0e0e0;
}


/* Logo container */
.logo-container {
    display: flex;
    align-items: center;
}

/* Logout button styling */
.logout-button {
    padding: 10px 15px;
    font-size: 1rem;
    background-color: #f44336;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-right: 40px;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #d32f2f;
}

/* JoiApp logo styling */
.logo {
    width: 40px; /* Adjust the width as needed */
    height: auto; /* Auto adjust the height to maintain aspect ratio */
    margin-right: 15px; /* More spacing between logo and app name */
}

.app-name {
    font-size: 1.8rem; /* Increase font size for more visibility */
    font-weight: bold;
    color: white;
   
}
 
