/* QuestionsPage.css */
/* Header styling */
/* Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    
    color: white;
    width: 100%;
    position: fixed; /* Ensure the header stays at the top */
    top: 0;
    left: 0;
    height: 60px; /* Adjust header height */
    z-index: 10; /* Make sure header stays above other content */
    box-sizing: border-box; /* Make sure padding and border are included in the height */
}

/* Logo container */
.logo-container {
    display: flex;
    align-items: center;
}

/* Logout button styling */
.logout-button {
    padding: 10px 15px;
    font-size: 1rem;
    background-color: #f44336;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-right: 40px;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #d32f2f;
}

/* JoiApp logo styling */
.logo {
    width: 40px; /* Adjust the width as needed */
    height: auto; /* Auto adjust the height to maintain aspect ratio */
    margin-right: 15px; /* More spacing between logo and app name */
}

.app-name {
    font-size: 1.8rem; /* Increase font size for more visibility */
    font-weight: bold;
    color: white;
   
}

/* Public Key Styling */
.public-key {
    font-size: 1rem;
    color: white;
    margin-top: 5px;
    margin-right: auto;
    margin-left: 30px; /* Spacing between logo and public key */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Video and Button Group */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    
    margin-top: 20px;
}

/* Spacing adjustments for elements below */
/* Button group styling */
.button-group {
    display: grid; /* Using grid for two-column layout */
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    gap: 15px; /* Increased gap for more space between buttons */
    width: 100%; /* Full width for the button container */
    max-width: 800px; /* Set max-width for larger screens */
    margin: 20px 0; /* Added some space above and below the button group */
}
/* Button styling */
.button-group button {
    padding: 15px; /* Increase padding for a larger clickable area */
    font-size: 1rem; /* Increase font size for better readability */
    background-color: #f4f4f4; /* Neutral background color */
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-group button:hover {
    background-color: #007bff; /* Change background on hover */
    color: white; /* Change text color on hover */
}
/* Responsive styling */
@media (min-width: 600px) {
    .button-group {
        flex-direction: row;
        justify-content: center;
    }

}

.video-container {
    width: 100%;
    max-width: 600px;
    margin: 20px 0; /* Adjust margin for better spacing */
    border: 2px solid #4CAF50; /* Green outline */
    padding: 10px;
    border-radius: 8px;
}

/* For larger screens */
@media (min-width: 768px) {
    .container {
        padding: 40px;
    }
}

/* For adjusting the top margin to balance the content better */
h1 {
    margin-top: 10;
}

/* Form styling */
.form-container {
    width: 100%;
    max-width: 600px;
    margin: 0; /* Removed margin to reduce gap */
}

.submit-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px; /* Space between buttons */
    align-items: center;
}

.submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px; /* Space between buttons */
    margin-left: 20px;
    transition: background-color 0.3s ease;
}
.submit-button:hover {
    background-color: #0056b3;
}
.submit-button:disabled {
    background-color: #cccccc; /* Light gray background */
    color: #666666; /* Dark gray text */
    cursor: not-allowed; /* Show "not allowed" cursor */
    opacity: 0.6; /* Reduce opacity */
}

.submit-button:hover:not(:disabled) {
    background-color: #0056b3;
}
.questions-page .form-container {
    max-width: 800px; /* Adjust as needed */
    margin: 0 auto;
}
.waiting-text {
    margin-left: 10px;
    font-style: italic;
    color: #666666;
}
.questions-page .form-container h2 {
    text-align: left;
    margin-bottom: 10px;
}

.questions-page .form-container .question-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.questions-page .form-container .question-container label {
    margin-bottom: 5px;
}

.questions-page .form-container .question-container .radio-group {
    display: flex;
    flex-direction: column;
}

.questions-page .form-container .question-container .radio-group label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.results-log {
    margin-bottom: 50px;
}