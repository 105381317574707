 
/* DashboardPage.css (Optional styling) */
.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-color: linear-gradient(135deg, #003366 0%, #6699CC 100%); 
  padding-top: 50px;
}

.dashboard {
  padding: 40px;
  margin-top: 100px;
  max-width: 600px;
  width: 100%;
  background-color:  linear-gradient(135deg, #003366 0%, #6699CC 100%); 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.dashboard-info {
  margin-top: 20px;
}

.info-item {
  margin-bottom: 10px;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.app-name {
  font-size: 1.5em;
  font-weight: bold;
}