body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #003366 0%, #6699CC 100%); /* Sky and technology-inspired colors */
  color: #fff;
}

/* Header styling */
.header {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  z-index: 10;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.app-name {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
}

/* Main container */
.landing-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

/* Centered login box */
.login-box {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 350px;
}

.title {
  margin-bottom: 20px;
  font-size: 2.5rem;
  font-weight: 700;
  color: #ffffff; /* Darker aviation blue for the title */
}

.subtitle {
  margin-bottom: 30px;
  font-size: 1.2rem;
  color: #666;
}

/* Button styling */
.button {
  padding: 15px 30px;
  font-size: 1rem;
  margin: 10px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.2s;
  width: 200px;
}

.button:hover {
  transform: translateY(-3px);
}

.register-button {
  background-color: #0066CC; /* Sky-inspired blue */
  color: white;
}

.register-button:hover {
  background-color: #004080; /* Slightly darker blue on hover */
}

.login-button {
  background-color: #339966; /* Calm, reassuring green like airline uniforms */
  color: white;
  padding: 15px 30px;
  font-size: 1rem;
  margin: 10px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.2s;
  width: 200px;
}

.login-button:hover {
  background-color: #26734d; /* Darker green on hover */
}

/* Footer or additional elements can be styled similarly */
